function scrollToElement(sourceId, targetId) {
    $(sourceId).click(() => {
        $.scrollTo($(targetId), 500, {
            offset : -180
        });
    });
}

scrollToElement('#genes-link', '#genes-section');
scrollToElement('#variants-link', '#variants-section');
scrollToElement('#proteins-link', '#proteins-section');
scrollToElement('#diseases-link', '#diseases-section');
scrollToElement('#genes-top', '#top-header');
scrollToElement('#literature-top', '#top-header');
scrollToElement('#variants-top', '#top-header');
scrollToElement('#proteins-top', '#top-header');
scrollToElement('#diseases-top', '#top-header');